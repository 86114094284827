<template>
    <div class="text-center" v-if="displayNotFound">
            <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4" style="margin-top:10%;width: 100%; max-width: 450px">
            <h1 class="text-h1 mb-6">{{ $t('common_reporting_template_projectNotFound_title') }}</h1>
            <p>{{ $t('common_reporting_template_projectNotFound_text') }}</p>
    </div>
    <div v-else-if="displayedReporting.data">
        <div v-if="displayNoReport" class="text-center">
            <img src="@/assets/images/simple-dashboard.png" alt="reporting" class="mx-auto mb-4" style="margin-top:10%;width: 100%; max-width: 450px">
            <h1 class="text-h1 mb-6">{{ $t('common_reporting_template_noReporting_title') }}</h1>
            <p v-html="displayDateNextReport"></p>
        </div>
        <div v-else-if="loadingComplete">

            <custom-report-model :template-components="templateComponents.data" v-if="templateComponents"/>

        </div>
        <div v-else>
            <div class="v-application text-center" style="top: 50%; position: fixed; left: 50%">
                <v-progress-circular :size="70" :width="7"  indeterminate color="primary"/>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="v-application text-center" style="top: 50%; position: fixed; left: 50%">
            <v-progress-circular :size="70" :width="7"  indeterminate color="primary"/>
        </div>
    </div>
</template>


<script>
import apiCalls from "@/api/axios/dataReport/reporting";
import apiCallsProject from "@/api/axios/configuration/project";
import apiCallsTemplate from "@/api/axios/dataReport/template";

import CustomReportModel from "@/components/yooda-components/reporting/template/CustomReportModel.vue";


export default {
    data() {
        return {
            displayedReporting:{  isLoaded:false, error:null,data: null },
            displayNotFound:false,
            loadingComplete:false,
            layout: [],
            templateComponents:null
        }
    },
    computed:{

        displayNoReport(){
            return !(this.displayedReporting.data && this.displayedReporting.data.currentReportingDate);
        },
        displayDateNextReport(){
            let date = ''
            if(this.displayedReporting.data && this.displayedReporting.data.nextReportingDate){
                date = this.$options.filters.displayDateLocale(this.displayedReporting.data.nextReportingDate)
            }

            return this.$t('common_reporting_template_noReporting_text').replace('[*DATE*]', date)
        }
    },
    methods:{
        initComponentData(){
            if(this.$route.query.hash){
                this.setDataComponent({
                    stateName:'projectInfo',
                    promise: apiCallsProject.getProjectInfo(this.$route.params.projectId, true),
                    dataFormatter: this.projectData
                }).then((result)=>{
                    if(result){
                        this.setDefaultLocationUsage()
                        this.getComponentData({
                            componentName:'displayedReporting',
                            promise: apiCalls.readReportingByHash(this.$route.query.hash)
                        }).then(()=>{
                            if(this.displayedReporting.error){
                                this.displayNotFound = true
                            }
                            else{
                                this.setGlobalFilterFrequency(this.displayedReporting.data.dateRangeType)
                                this.getComponentData({
                                    componentName:'templateComponents',
                                    promise: apiCallsTemplate.readTemplateById(this.displayedReporting.data.template.id),
                                    dataFormatter: this.getTemplateComponentReporting
                                })
                                this.loadingComplete = true
                            }
                        })
                    }
                })
            }
            else{
                this.displayNotFound = true
            }
        },
    },
    components: {
        CustomReportModel,
    },
    beforeMount() {
        this.initComponentData()
    },
}

</script>

<style lang="scss">

</style>
